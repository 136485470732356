<template>
    <div class='teacher__container'>
        <h4 class='font-weight-bold mb-4'>{{ $t('profile.title') }}</h4>
        <b-tabs content-class='mt-0'>
            <b-tab :title='$t("profile.main_inf")'>
                <TeacherProfile />
            </b-tab>
            <b-tab :title='$t("profile.profile")'>
                <TeacherProfileDetails />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import TeacherProfile from './MyProfile';
import TeacherProfileDetails from './TeacherProfileDetails';

export default {
    name: 'TeacherProfileContainer',
    components: { TeacherProfile, TeacherProfileDetails },
};
</script>

<style lang='scss' scoped>
.page__title {
    font-weight: 700;

}
</style>