<template>
    <div class='teacher__details'>
            <b-form class='row g-1'>
                <div class='col-2'>
                    <b-form-group
                        :label='$t("profile.avatar")'
                        class='col-12 p-0'
                        label-for='avatar'
                        @submit.prevent='handleSave'
                    >
                        <div class='d-flex flex-column justify-center'>
                            <img :src='fileUrl' alt='#' />

                            <avatar-cropper
                                uploadFileField='avatar'
                                v-model='showCropper'
                                :uploadUrl='`${baseUrl}/api/teacher/upload-avatar`'
                                @completed='handleCompleted'
                            />

                            <a
                                class='btn btn-primary mt-2'
                                @click.prevent='showCropper=true'
                            >
                              <i class='fa fa-image mr-2'></i>{{ $t("buttons.load_ava") }}
                            </a>
                        </div>
                    </b-form-group>
                </div>
                <div class='col-10'>
                    <div class='row'>
                      <b-form-group
                          :label='$t("profile.locations")'
                          class="col-4"
                          label-for="input-language"
                      >
                        <Multiselect
                            id="input-language"
                            class="custom__multiselect"
                            selectLabel="нажмите чтобы выбрать"
                            deselectLabel="нажмите чтобы убрать"
                            placeholder="Выберите локацию"
                            v-model="form.locations"
                            :taggable="true"
                            :close-on-select="false"
                            :multiple="true"
                            :options="getLocationList"
                            :value="form.locations"
                            label='text'
                            track-by='value'
                        >
                        </Multiselect>
                      </b-form-group>

                        <b-form-group
                            :label='$t("profile.degree")'
                            class='col-4'
                            label-for='degree'
                        >
                            <b-form-select
                                id='degree'
                                type='text'
                                :placeholder='$t("profile.degree")'
                                v-model='form.degree'
                                required
                            >
                                <b-form-select-option v-for='(value, key) in degreeList' :key='key' :value='key'>
                                  {{ value }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-invalid-feedback>
                                {{ $t('errors.not_empty') }}
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            :label='$t("profile.exp")'
                            class='col-4'
                            label-for='exp'
                        >
                            <b-form-input
                                id='exp'
                                type='number'
                                min='1'
                                :placeholder='$t("profile.exp")'
                                v-model='form.experience'
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                {{ $t('errors.not_empty') }}
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            :label='$t("profile.country")'
                            class='col-4'
                            label-for='country'
                        >
                            <b-form-select v-model='form.country'>
                                <b-form-select-option v-for='(value, key) in countries' :key='key' :value='value'>
                                  {{ value }}
                                </b-form-select-option>
                            </b-form-select>
                            <!--                            <b-form-input-->
                            <!--                                id='country'-->
                            <!--                                type='text'-->
                            <!--                                v-model='form.country'-->
                            <!--                                placeholder='ссылка на шахматную доску'-->
                            <!--                                required-->
                            <!--                            ></b-form-input>-->
                            <b-form-invalid-feedback>
                                {{ $t('errors.not_empty') }}
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            :label='$t("profile.video_url")'
                            class='col-8'
                            label-for='videourl'
                        >
                            <b-form-input
                                id='videourl'
                                type='text'
                                v-model='form.video_url'
                                :placeholder='$t("profile.video_url")'
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                {{ $t('errors.not_empty') }}
                            </b-form-invalid-feedback>
                        </b-form-group>


                        <b-form-group
                            :label='$t("profile.age_from")'
                            class='col-3'
                            label-for='agefrom'
                        >
                            <b-form-input
                                id='agefrom'
                                type='text'
                                v-model='form.age_from'
                                :placeholder='$t("profile.age_from")'
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                {{ $t('errors.not_empty') }}
                            </b-form-invalid-feedback>
                        </b-form-group>


                        <b-form-group
                            :label='$t("profile.age_to")'
                            class='col-3'
                            label-for='ageto'
                        >
                            <b-form-input
                                id='ageto'
                                type='text'
                                v-model='form.age_to'
                                :placeholder='$t("profile.age_to")'
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                {{ $t('errors.not_empty') }}
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            :label='$t("profile.descr")'
                            class='col-12'
                            label-for='descr'
                        >
                            <b-form-textarea
                                id='descr'
                                type='text'
                                v-model='form.description'
                                :placeholder='$t("profile.descr___plc")'
                                required
                            ></b-form-textarea>
                            <b-form-invalid-feedback>
                                {{ $t('errors.not_empty') }}
                            </b-form-invalid-feedback>
                        </b-form-group>


                        <b-form-group
                            :label='$t("profile.descr_en")'
                            class='col-12'
                            label-for='descr_en'
                        >
                            <b-form-textarea
                                id='descr_en'
                                type='text'
                                v-model='form.description_en'
                                :placeholder='$t("profile.descr_en___plc")'
                                required
                            ></b-form-textarea>
                            <b-form-invalid-feedback>
                                {{ $t('errors.not_empty') }}
                            </b-form-invalid-feedback>
                        </b-form-group>

                    </div>

                    <div class='row justify-content-end p-4'>
                        <button type='submit' @click.prevent='handleSave' class='btn btn-success'>{{ $t('buttons.save') }}</button>
                    </div>
                </div>
            </b-form>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import AvatarCropper from '../../components/AvatarCropper/AvatarCropper';
import { mapGetters } from "vuex";

export default {
    name: 'TeacherProfileDetails.vue',
    components: { AvatarCropper, Multiselect },
    data() {
        return {
            teacherId: this.$store.state.user.teacher_profile.id,
            showCropper: false,
            fileUrl: 'https://place-hold.it/200x200',
            thumbUrl: 'https://place-hold.it/200x200',
            form: {
                locations: null,
                degree: null,
                experience: null,
                country: null,
                video_url: null,
                age_from: null,
                age_to: null,
                description: this.fileUrl,
                published: false,
                description_en: null
            },
            fileName: 'unknown',
        };
    },
    async created() {
        if (this.teacherId) {
            await this.loadUserProfile(this.teacherId);
        }
    },
    computed: {
        ...mapGetters({
          getLocationList: 'common/getLocationList',
          degreeList: 'common/getTeacherDegree',
          countries: 'common/getCountries',
        }),
        baseUrl() {
            return process.env.VUE_APP_API_BASE_URL;
        },
    },
    methods: {
        async loadUserProfile(userId) {
            const { data } = await this.$axios.get(`/api/teacher/profile/${userId}`);
            if (data && !data.error && data.profile) {
                const profile = data.profile;
                this.form.degree = profile.degree;
                this.form.experience = profile.experience;
                this.form.description = profile.description;
                this.form.description_en = profile.description_en;
                this.form.country = profile.country;
                this.form.video_url = profile.video_url;
                this.form.rating = profile.rating;
                this.form.age_from = profile.age_category.split(',')[0];
                this.form.age_to = profile.age_category.split(',')[1];
                this.fileUrl = profile.avatar_url;
                this.thumbUrl = profile.avatar_thumb;

                if (profile.locations && profile.locations.length) {
                  const locations = [];

                  this.getLocationList.forEach(location => {
                    profile.locations.forEach(profileLocation => {
                      if (profileLocation.value === location.value) locations.push(location);
                    })
                  });

                  this.form.locations = locations;
                }
            }
        },
        async handleUpload(form, response) {
        },
        handleFileChange({ file, reader }) {
            this.fileName = file.name || 'unknown';
        },
        async handleCompleted({ form, request, response }) {
            const data = response.data;
            if (data.error) {
                alert('произошла ошибка при загрузке');
            }
            this.fileUrl = data.relative_url;
            this.thumbUrl = data.thumb_url;
        },
        async updateProfile() {
            return await this.$axios.post(`/api/teacher/profile`, {
                ...this.form,
                teacher_id: this.teacherId,
                avatar_url: this.fileUrl,
                avatar_thumb: this.thumbUrl,
            });
        },
        async handleSave() {
            const { data } = await this.updateProfile();
            if (data.success) {
                this.$toasted.success('Данные сохранены', { position: 'bottom-right' });
            } else if (data.error) {
                this.$toasted.error(Object.keys(data.errors).map(item => data.errors[item]).join('\n'), { position: 'bottom-right' });
            }
        },
    },

};
</script>

<style lang='scss' scoped>
::v-deep {
    textarea {
        border: 1px solid #f1f1f1;
    }
    label {
        font-size: 14px;
        color: #4d4d4d;
        font-weight: 300;
    }
    .form__group {
        justify-content: flex-end;
        label {
            margin: 0;
        }
    }
}
</style>