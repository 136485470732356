<template>
    <div>
        <b-form class='row g-1' @submit.prevent='sendData' @reset.prevent='back'>
            <b-form-group
                id='input-group-1'
                :label="$t('profile.fio')"
                class='col-6 form__label'
                label-for='fio'
            >
                <b-form-input
                    id='fio'
                    v-model='form.full_name'
                    type='text'
                    :placeholder="$t('profile.fio')"
                    required
                    :state='nameValidation'
                ></b-form-input>
                <b-form-invalid-feedback>
                    {{ $t('profile.fio___err') }}
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
                id='input-group-1'
                :label="$t('profile.short_n')"
                class='col-6 form__label'
                label-for='shortname'
            >
                <b-form-input
                    id='shortname'
                    v-model='form.short_name'
                    type='text'
                    :placeholder="$t('profile.short_n')"
                    required
                    :state='nameValidation'
                ></b-form-input>
                <b-form-invalid-feedback>
                    {{ $t('profile.short_n___err') }}
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
                id='input-group-1'
                :label="$t('profile.gender')"
                class='col-6 form__label'
                label-for='input-1'
            >
                <b-form-select
                    id='inline-form-custom-select-pref'
                    class=''
                    :options='getGenderList'
                    :value='form.gender'
                    v-model='form.gender'
                ></b-form-select>
            </b-form-group>

            <b-form-group
                id='input-group-1'
                :label="$t('profile.teach_lang')"
                class='col-6 form__label'
                label-for='input-lang'
            >
                <b-form-select
                    id='input-lang'
                    class=''
                    :options='getLanguageList'
                    :value='form.language'
                    v-model='form.language'
                ></b-form-select>
            </b-form-group>

            <b-form-group
                id='input-group-1'
                :label="$t('profile.phone')"
                class='col-6 form__label'
                label-for='phone'
            >
                <b-form-input
                    id='phone'
                    v-model='form.phone'
                    type='text'
                    :placeholder="$t('profile.phone')"
                    required
                    :state='nameValidation'
                ></b-form-input>
                <b-form-invalid-feedback>
                    {{ $t('profile.phone___err') }}
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
                id='input-group-1'
                label='Email:'
                class='col-6 form__label'
                label-for='email'
            >
                <b-form-input
                    id='email'
                    v-model='form.email'
                    type='text'
                    placeholder='email'
                    required
                    :state='nameValidation'
                ></b-form-input>
                <b-form-invalid-feedback>
                    {{ $t('profile.email___err') }}
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
                id='input-group-1'
                :label="$t('profile.zoom')"
                class='col-6 form__label'
                label-for='zoom_url'
            >
                <b-form-textarea
                    id='zoom_url'
                    v-model='form.zoom_url'
                    type='text'
                    :placeholder="$t('profile.zoom')"
                ></b-form-textarea>
                <b-form-invalid-feedback>
                    {{ $t('profile.zoom___err') }}
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
                id='input-group-1'
                :label="$t('profile.lichess_username')"
                class='col-6 form__label'
                label-for='email'
            >
              <b-form-input
                  id='lichess_username'
                  v-model='form.lichess_username'
                  type='text'
              ></b-form-input>
            </b-form-group>

            <b-form-group class="col-6" :label="$t('profile.li_room_url')" label-for='li_room_url'>
                <b-input-group class='lichess-room-url-input-group'>
                    <b-form-input
                        id='li_room_url'
                        v-model='form.lichess_room_url'
                        type='text'
                        placeholder='Ссылка на комнату'
                        ref='lichessRoomUrl'
                        disabled
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button variant='info' @click.prevent="(e) => copyLink(e, 'lichessRoomUrl')">Скопировать</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>

            <b-form-group class='col-3 text-left' :label="$t('profile.zoom_login')">
              <b-form-input
                  id='zoom_login'
                  v-model='form.zoom_login'
                  disabled
                  type='text'
              ></b-form-input>
            </b-form-group>

            <b-form-group class='col-3 text-left' :label="$t('profile.zoom_password')">
              <b-form-input
                  id='zoom_password'
                  disabled
                  v-model='form.zoom_password'
                  type='text'
              ></b-form-input>
            </b-form-group>

          <b-form-group v-if="auth_zoom_url" class="col-6" :label="$t('profile.auth_zoom_url')" label-for='li_zoom_auth'>
            <b-input-group class='lichess-room-url-input-group'>
              <b-form-input
                  id='li_room_url'
                  v-model='auth_zoom_url'
                  type='text'
                  placeholder='Ссылка на комнату'
                  ref='authZoom'
                  disabled
              ></b-form-input>
              <b-input-group-append>
                <b-button variant='info' @click.prevent="(e) => copyLink(e, 'authZoom')">Скопировать</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>


            <b-form-group class='col-12 text-right'>
                <b-button type='submit' class='mr-2' variant='success' :disabled='!canSend'>{{ $t('buttons.save') }}
                </b-button>
                <b-button type='reset' variant='primary'>{{ $t('buttons.back') }}</b-button>
            </b-form-group>

        </b-form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'MyProfile',
    data() {
        return {
          auth_zoom_url: null,
          form: {
                id: null,
                full_name: null,
                short_name: null,
                phone: null,
                email: null,
                login: null,
                zoom_url: null,
                gender: null,
                language: null,
                lichess_username: null,
                lichess_login: null,
                lichess_password: null,
                lichess_room_url: null,
                zoom_login: null,
                zoom_password: null
            },
        };
    },
    computed: {
        ...mapGetters({
            getTeacherProfile: 'user/getTeacherProfile',
            getGenderList: 'common/getGenderList',
            getLanguageList: 'common/getLanguageList',
        }),
        nameValidation() {
            return !!this.form.full_name && this.form.full_name.length > 4;
        },
        shortNameValidation() {
            return !!this.form && !!this.form.short_name && this.form.short_name.length >= 5;
        },
        phoneNumberValidator() {
            return this.form.phone ? (this.form.phone.length >= 6) : false;
        },
        timeValidation() {
            return !!this.form.time;
        },
        canSend() {
            return this.nameValidation
                & this.shortNameValidation
                & this.phoneNumberValidator;
        },
    },
    methods: {
        ...mapActions({
            updateTeacherProfile: 'user/updateTeacherProfile',
            fetchTeacherProfile: 'user/fetchTeacherProfile',
        }),
        buildForm() {
            this.auth_zoom_url = this.getTeacherProfile.auth_zoom_url;
            for (const key in this.getTeacherProfile) {
                const formKeys = Object.keys(this.form);
                if (formKeys.includes(key)) {
                    this.form[key] = this.getTeacherProfile[key];
                }
            }
        },
        async sendData() {
            try {
                await this.updateTeacherProfile({ ...this.form });
                this.$toasted.success(this.$t('toasted.Данные обновлены!'), {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
                window.location.reload();
            } catch (e) {
                this.$toasted.error(`${this.$t('toasted.Не удалось сохранить!')} ${e}`, {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }

        },
        back() {
            this.$router.back();
        },
        copyLink(e, ref) {
            const elem = this.$refs[ref];
            navigator.clipboard.writeText(elem.value);
            this.$toasted.success('скопировано в буфер обмена', {
                position: 'bottom-right',
                action: {
                    text: 'Закрыть',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
        },
    },
    created() {
        this.buildForm();
    },
};
</script>

<style lang='scss' scoped>
.form__label {
    ::v-deep {
        input {
            padding: 15px;
        }

        label {
            font-size: 14px;
            color: #4d4d4d;
            font-weight: 300;
        }

        textarea {
            border: 1px solid #f1f1f1;
        }
    }
}
</style>